/* eslint-disable react/no-unknown-property */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable react/prop-types */
/** ========================================================= * NextJS Material Dashboard 2 PRO - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro * Copyright 2022 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import { useState, useEffect, useMemo } from 'react';
import { MaterialUIControllerProvider, useMaterialUIController, setOpenConfigurator } from 'context';

// next
import dynamic from 'next/dynamic';
import Head from 'next/head';
import { useRouter } from 'next/router';
import { Roboto } from 'next/font/google';
import { Analytics } from '@vercel/analytics/react';

// @emotion
import createCache from '@emotion/cache';

// @emotion/react components
import { CacheProvider } from '@emotion/react';

// MUI
import { ThemeProvider } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

// PRO
import MDBox from 'components/MDBox';

// NextJS Material Dashboard 2 PRO examples
import Configurator from 'examples/Configurator';

// NextJS Material Dashboard 2 PRO themes
import theme from 'assets/theme';
import themeRTL from 'assets/theme/theme-rtl';

// NextJS Material Dashboard 2 PRO Dark Mode themes
import themeDark from 'assets/theme-dark';
import themeDarkRTL from 'assets/theme-dark/theme-rtl';

// RTL plugins
import rtlPlugin from 'stylis-plugin-rtl';

// MUI Icons
const SettingsRounded = dynamic(() => import('@mui/icons-material/SettingsRounded'), { loading: () => null });

const roboto = Roboto({
  weight: ['300', '400', '500', '700', '900'],
  subsets: ['latin'],
  display: 'swap',
  fallback: ['Arial'],
  style: ['normal'],
});

// Client-side cache, shared for the whole session of the user in the browser.
const clientSideEmotionCache = createCache({ key: 'css', prepend: true });

function Main({ Component, pageProps }) {
  const [controller, dispatch] = useMaterialUIController();
  const {
    direction, openConfigurator, darkMode,
  } = controller;
  const [rtlCache, setRtlCache] = useState(null);
  const { pathname } = useRouter();

  // Cache for the rtl
  useMemo(() => {
    const cacheRtl = createCache({
      key: 'rtl',
      stylisPlugins: [rtlPlugin],
    });
    setRtlCache(cacheRtl);
  }, []);

  // Setting the dir attribute for the body element
  useEffect(() => {
    document.body.setAttribute('dir', direction);
  }, [direction]);

  // Setting page scroll to 0 when changing the route
  useEffect(() => {
    document.documentElement.scrollTop = 0;
    document.scrollingElement.scrollTop = 0;
  }, [pathname]);

  const configsButton = (
    <MDBox
      display="flex"
      justifyContent="center"
      alignItems="center"
      width="3.25rem"
      height="3.25rem"
      bgColor="white"
      shadow="sm"
      borderRadius="50%"
      position="fixed"
      right="2rem"
      bottom="2rem"
      zIndex={99}
      color="dark"
      sx={{ cursor: 'pointer' }}
      onClick={() => setOpenConfigurator(dispatch, !openConfigurator)}
    >
      <SettingsRounded color="inherit" sx={{ fontSize: 'small' }} />
    </MDBox>
  );

  return direction === 'rtl' ? (
    <CacheProvider value={rtlCache}>
      <ThemeProvider theme={darkMode ? themeDarkRTL : themeRTL}>
        <CssBaseline />
        <Component {...pageProps} />
        <Analytics />
        <Configurator />
        {configsButton}
      </ThemeProvider>
    </CacheProvider>
  ) : (
    <ThemeProvider theme={darkMode ? themeDark : theme}>
      <CssBaseline />
      <Component {...pageProps} />
      <Analytics />
      <Configurator />
      {configsButton}
    </ThemeProvider>
  );
}

function MyApp({
  Component,
  pageProps,
  emotionCache = clientSideEmotionCache,
}) {
  return (
    <MaterialUIControllerProvider>
      <CacheProvider value={emotionCache}>
        <Head>
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="apple-touch-icon" sizes="180x180" href="/apple-touch-icon.png" />
          <link rel="icon" type="image/png" sizes="32x32" href="/favicon-32x32.png" />
          <link rel="icon" type="image/png" sizes="16x16" href="/favicon-16x16.png" />
          <link rel="manifest" href="/site.webmanifest" />
          <link rel="mask-icon" href="/safari-pinned-tab.svg" color="#5bbad5" />
          <meta name="msapplication-TileColor" content="#000000" />
          <meta name="theme-color" content="#ffffff" />
          <title>CodeFox</title>
        </Head>
        <main className={roboto.className}>
          <Main Component={Component} pageProps={pageProps} />
        </main>
      </CacheProvider>
    </MaterialUIControllerProvider>
  );
}

export default MyApp;
