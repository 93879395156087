/**
========================================================= * NextJS Material Dashboard 2 PRO - v2.0.0 ========================================================= * Product Page: https://www.creative-tim.com/product/nextjs-material-dashboard-pro * Copyright 2022 Creative Tim (https://www.creative-tim.com) Coded by www.creative-tim.com ========================================================= * The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software. */

// react
import {
  useMaterialUIController,
  setOpenConfigurator,
  setSidenavColor,
  setDarkMode,
} from 'context';

// next
import dynamic from 'next/dynamic';
import Link from 'next/link';

// PRO
const MDBox = dynamic(() => import('components/MDBox'), { loading: () => null });
const MDTypography = dynamic(() => import('components/MDTypography'), { loading: () => null });

// MUI
const Divider = dynamic(() => import('@mui/material/Divider'), { loading: () => null });
const IconButton = dynamic(() => import('@mui/material/IconButton'), { loading: () => null });
const { Link: MuiLink } = dynamic(() => import('@mui/material/Link'), { loading: () => null });
const Switch = dynamic(() => import('@mui/material/Switch'), { loading: () => null });

// MUI Icons
const Close = dynamic(() => import('@mui/icons-material/Close'), { loading: () => null });
const Facebook = dynamic(() => import('@mui/icons-material/Facebook'), { loading: () => null });
const Twitter = dynamic(() => import('@mui/icons-material/Twitter'), { loading: () => null });

// PRO
const MDButton = dynamic(() => import('components/MDButton'), { loading: () => null });

// Parts
const ConfiguratorRoot = dynamic(() => import('examples/Configurator/ConfiguratorRoot'), { loading: () => null });

function Configurator() {
  // const router = useRouter();
  const [controller, dispatch] = useMaterialUIController();
  const {
    openConfigurator,
    sidenavColor,
    darkMode,
  } = controller;
  const sidenavColors = [
    'primary',
    'dark',
    'info',
    'success',
    'warning',
    'error',
  ];

  return (
    <ConfiguratorRoot variant="permanent" ownerState={{ openConfigurator }}>
      <MDBox
        display="flex"
        justifyContent="space-between"
        alignItems="baseline"
        pt={4}
        pb={0.5}
        px={3}
      >
        <MDBox>
          <MDTypography variant="h5" component="h2">UI Configurator</MDTypography>
          <MDTypography variant="body2" color="text">
            Choose your options.
          </MDTypography>
        </MDBox>

        <Close
          sx={({ typography: { size }, palette: { dark, white } }) => ({
            fontSize: `${size.lg} !important`,
            color: darkMode ? white.main : dark.main,
            stroke: 'currentColor',
            strokeWidth: '2px',
            cursor: 'pointer',
            transform: 'translateY(5px)',
          })}
          onClick={() => setOpenConfigurator(dispatch, false)}
        />
      </MDBox>

      {/* <Divider /> */}

      <MDBox pt={0.5} pb={3} px={3}>

        <MDBox>
          <MDTypography variant="h6" component="h3">Accent Color</MDTypography>

          <MDBox mb={0.5}>
            {sidenavColors.map((color) => (
              <IconButton
                aria-label={color}
                key={color}
                sx={({
                  borders: { borderWidth },
                  palette: { white, dark, background },
                  transitions,
                }) => ({
                  width: '24px',
                  height: '24px',
                  padding: 0,
                  border: `${borderWidth[1]} solid ${darkMode ? background.sidenav : white.main}`,
                  borderColor: () => {
                    let borderColorValue = sidenavColor === color && dark.main;

                    if (darkMode && sidenavColor === color) {
                      borderColorValue = white.main;
                    }

                    return borderColorValue;
                  },
                  transition: transitions.create('border-color', {
                    easing: transitions.easing.sharp,
                    duration: transitions.duration.shorter,
                  }),
                  backgroundImage: ({
                    functions: { linearGradient },
                    palette: { gradients },
                  }) => linearGradient(
                    gradients[color].main,
                    gradients[color].state,
                  ),

                  '&:not(:last-child)': {
                    mr: 1,
                  },

                  '&:hover, &:focus, &:active': {
                    borderColor: darkMode ? white.main : dark.main,
                  },
                })}
                onClick={() => setSidenavColor(dispatch, color)}
              />
            ))}
          </MDBox>
        </MDBox>

        {/*  <MDBox mt={3} lineHeight={1}>
          <MDTypography variant="h6">Sidenav Type</MDTypography>
          <MDTypography variant="button" color="text">
            Choose between different sidenav types.
          </MDTypography>

          <MDBox
            sx={{
              display: "flex",
              mt: 2,
              mr: 1,
            }}
          >
            <MDButton
              color="dark"
              variant="gradient"
              onClick={handleDarkSidenav}
              disabled={disabled}
              fullWidth
              sx={
                !transparentSidenav && !whiteSidenav
                  ? sidenavTypeActiveButtonStyles
                  : sidenavTypeButtonsStyles
              }
            >
              Dark
            </MDButton>
            <MDBox sx={{ mx: 1, width: "8rem", minWidth: "8rem" }}>
              <MDButton
                color="dark"
                variant="gradient"
                onClick={handleTransparentSidenav}
                disabled={disabled}
                fullWidth
                sx={
                  transparentSidenav && !whiteSidenav
                    ? sidenavTypeActiveButtonStyles
                    : sidenavTypeButtonsStyles
                }
              >
                Transparent
              </MDButton>
            </MDBox>
            <MDButton
              color="dark"
              variant="gradient"
              onClick={handleWhiteSidenav}
              disabled={disabled}
              fullWidth
              sx={
                whiteSidenav && !transparentSidenav
                  ? sidenavTypeActiveButtonStyles
                  : sidenavTypeButtonsStyles
              }
            >
              White
            </MDButton>
          </MDBox>
            </MDBox> */}
        {/* <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          mt={3}
          lineHeight={1}
        >
          <MDTypography variant="h6">Navbar Fixed</MDTypography>

          <Switch checked={fixedNavbar} onChange={handleFixedNavbar} />
        </MDBox>

        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <MDTypography variant="h6">Sidenav Mini</MDTypography>

          <Switch checked={miniSidenav} onChange={handleMiniSidenav} />
        </MDBox>
        */}
        <Divider />
        <MDBox
          display="flex"
          justifyContent="space-between"
          alignItems="center"
          lineHeight={1}
        >
          <MDTypography variant="h6" component="label" htmlFor="light-dark-switch">Light / Dark</MDTypography>

          <Switch id="light-dark-switch" checked={darkMode} onChange={() => setDarkMode(dispatch, !darkMode)} />
        </MDBox>
        <Divider />
        <MDBox mt={3} mb={2}>
          <MDBox mb={2}>
            <Link href="/#contact" scroll={false}>
              <MDButton
                onClick={() => setOpenConfigurator(dispatch, false)}
                // href="/#contact"
                color="dark"
                // variant="gradient"
                fullWidth
              >
                contact
              </MDButton>
            </Link>
          </MDBox>
        </MDBox>
        <MDBox mt={2} textAlign="center">
          <MDBox mb={0.5}>
            <MDTypography variant="h6" component="h3">Thank you for sharing!</MDTypography>
          </MDBox>

          <MDBox display="flex" justifyContent="center">
            <MDBox mr={1.5}>
              <MDButton
                component={MuiLink}
                href="https://twitter.com/intent/tweet?text=Check%20out%20codefox.com&amp;url=https%3A%2F%2Fcodefox.com"
                target="_blank"
                rel="noreferrer"
                color="dark"
              >
                <Twitter />
                &nbsp; Tweet
              </MDButton>
            </MDBox>
            <MDButton
              component={MuiLink}
              href="https://www.facebook.com/sharer/sharer.php?u=https://codefox.com"
              target="_blank"
              rel="noreferrer"
              color="dark"
            >
              <Facebook />
              &nbsp; Share
            </MDButton>
          </MDBox>
        </MDBox>
      </MDBox>
    </ConfiguratorRoot>
  );
}

export default Configurator;
